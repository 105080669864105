
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { SaveCrmCustomerLeadRequest } from '@/models/Interfaces';
import { CrmCustomerLeadEditorModel, IdNameActive, CrmCustomerLeadToUpdateDetalis } from '@/models/CrmCustomers';
import router from '@/router';
import { api } from '@/services/Api';
import q from 'q';
import { CrmCustomerLeadSaveChanges } from '@/models/Enums';
import CrmCustomerLeadForm from '@/components/CrmCustomerLead/CrmCustomerLeadForm.vue';
import _ from 'lodash';
import { getFlagChanges } from '@/helper';

export default defineComponent({
    props: {
        crmCustomerId: {
            type: String || null,
            default: null
        }
    },
    components: {
        OverlayModal,
        CrmCustomerLeadForm
    },
    name: 'CrmCustomerLeadEditorModal',
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const isOpen = ref(false);
        const originCrmCustomerLead = ref(new CrmCustomerLeadEditorModel());
        const crmCustomerLead = ref(new CrmCustomerLeadEditorModel());
        const leadTypes = ref<IdNameActive[]>([]);
        const leadSources = ref<IdNameActive[]>([]);
        const crmCustomerLeadForm = ref<InstanceType<typeof CrmCustomerLeadForm>>();

        async function open(crmCustomerLeadToEdit: CrmCustomerLeadEditorModel | null = null) {
            swal.showLoading();
            await q.delay(400);
            if (crmCustomerLeadToEdit) {
                originCrmCustomerLead.value = _.clone(crmCustomerLeadToEdit);
                crmCustomerLead.value = _.clone(crmCustomerLeadToEdit);
            } else {
                originCrmCustomerLead.value = new CrmCustomerLeadEditorModel();
                crmCustomerLead.value = new CrmCustomerLeadEditorModel();
            }
            const response = await api.GetCrmCustomerLeadTypes();
            if (response?.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return null;
            }
            leadTypes.value = response.data.types;
            const response2 = await api.GetCrmCustomerLeadSources();
            if (response2?.errorMessage || !response2?.data) {
                swal.fire({
                    icon: 'error',
                    text: response2.errorMessage || 'error'
                });
                return null;
            }
            leadSources.value = response2.data.sources;

            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        async function onSave() {
            swal.showLoading();
            let changes;
            if (crmCustomerLead.value.id) {
                changes = getFlagChanges(originCrmCustomerLead.value, crmCustomerLead.value, CrmCustomerLeadSaveChanges, key => {
                    if (key === 'leadSourceId') return 'LeadSource';
                    if (key === 'leadTypeId') return 'LeadType';
                    return null;
                });
            } else {
                changes = CrmCustomerLeadSaveChanges.NewEntity;
            }
            const req: SaveCrmCustomerLeadRequest = {
                id: crmCustomerLead.value.id,
                crmCustomerId: props.crmCustomerId ?? crmCustomerLead.value.crmCustomerId,
                description: crmCustomerLead.value.description,
                leadSourceId: crmCustomerLead.value.leadSourceId,
                leadTypeId: crmCustomerLead.value.leadTypeId,
                active: crmCustomerLead.value.active,
                numberOfGuests: Number(crmCustomerLead.value.numberOfGuests),
                remarks: crmCustomerLead.value.remarks,
                status: crmCustomerLead.value.status,
                changes: changes
            };

            const apiResponse = await api.saveCrmCustomerLead(req);

            if (apiResponse.validationErrors?.length) {
                crmCustomerLeadForm.value?.setValidator(apiResponse.validationErrors);
                swal.close();
                return null;
            }
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return null;
            }
            swal.close();
            return apiResponse.data;
        }
        const submitForm = async (event: any) => {
            crmCustomerLeadForm.value?.clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            const crmCustomerLeadToUpdate = await onSave();

            if (crmCustomerLeadToUpdate == null) {
                return;
            }
            close();
            if (crmCustomerLead.value.id) {
                const toUpdateModel: CrmCustomerLeadToUpdateDetalis = {
                    description: crmCustomerLeadToUpdate.description,
                    leadSourceId: crmCustomerLeadToUpdate.leadSourceId,
                    leadSourceName: leadSources.value.find(x => x.id == crmCustomerLeadToUpdate.leadSourceId)?.name ?? '',
                    leadTypeId: crmCustomerLeadToUpdate.leadTypeId,
                    leadTypeName: leadTypes.value.find(x => x.id == crmCustomerLeadToUpdate.leadTypeId)?.name ?? '',
                    numberOfGuests: crmCustomerLeadToUpdate.numberOfGuests
                };
                emit('updatecrmCustomerLeadDetails', toUpdateModel);
            } else {
                router.push({
                    name: 'crm-customer-lead-details',
                    params: { crmCustomerId: crmCustomerLeadToUpdate.crmCustomerId, crmCustomerLeadId: crmCustomerLeadToUpdate.id }
                });
            }
        };

        return {
            isOpen,
            open,
            close,
            crmCustomerLead,
            leadTypes,
            leadSources,
            submitForm,
            crmCustomerLeadForm
        };
    }
});
