
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { CrmCustomerTimingChatMessage, CrmCustomerTimingChatMessageEditModel } from '@/models/CrmCustomers';
import _ from 'lodash';
import { api } from '@/services/Api';
import { SaveTimingMessageRequest } from '@/models/Interfaces';
import moment from 'moment';
import { DateConstants } from '@/models/Constants';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import q from 'q';
import TimeInput from '@/components/TimeInput.vue';
import Calendar from 'primevue/calendar';

export default defineComponent({
    props: {
        crmCustomerId: {
            type: String,
            required: true
        },
        crmCustomerLeadId: {
            type: String || null,
            default: null
        }
    },
    components: {
        OverlayModal,
        Calendar,
        TimeInput
    },
    name: 'TimingChatMessagePopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const { t } = useI18n();
        const isNew = ref(true);
        const loading = ref(false);
        const dateConstants = DateConstants;
        const timingChatMessage = ref(new CrmCustomerTimingChatMessageEditModel());
        const scheduleDate = ref<Date | null>(null);
        const customerFullName = ref('');
        async function open(customerFullNameProp: string, timingChatMessageParam: CrmCustomerTimingChatMessage | null = null) {
            customerFullName.value = customerFullNameProp;
            timingChatMessage.value = new CrmCustomerTimingChatMessageEditModel();
            isNew.value = true;
            scheduleDate.value = null;
            if (timingChatMessageParam) {
                isNew.value = false;
                const dateArr = timingChatMessageParam.scheduleDateTime.split(' ');
                scheduleDate.value = moment(dateArr[0], DateConstants.DATE_FORMAT_SHOW).toDate();
                timingChatMessage.value.id = timingChatMessageParam.id;
                timingChatMessage.value.relativeInterval = timingChatMessageParam.relativeInterval;
                timingChatMessage.value.message = timingChatMessageParam.message;
                timingChatMessage.value.scheduleDate = dateArr[0];
                timingChatMessage.value.scheduleTime = dateArr[1];
            }
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        function updateScheduleTime(val?: string) {
            if (val) {
                timingChatMessage.value.scheduleTime = val;
            }
        }
        function updateScheduleDate(event: Date) {
            timingChatMessage.value.scheduleDate = moment(event).format(DateConstants.DATE_FORMAT_SHOW);
        }
        async function updateScheduleDateTime() {
            if (!timingChatMessage.value.scheduleTime || !scheduleDate.value) {
                return;
            }
            loading.value = true;
            const scheduleDateString = moment(scheduleDate.value).format(DateConstants.DATE_FORMAT_SHOW);
            const scheduleDateTime = `${scheduleDateString} ${timingChatMessage.value.scheduleTime}`;
            const response = await api.getRelativeInterval(scheduleDateTime);
            if (response.errorMessage || !response.data) {
                return;
            }
            timingChatMessage.value.relativeInterval = response.data.relativeInterval;
            loading.value = false;
        }
        function setNotValid(errMsg: string, fieldId: string, fieldErrorId: string) {
            const field = document.getElementById(fieldId) as HTMLInputElement;
            field.classList.add('error-input-style');
            field.setCustomValidity(errMsg);
            const fieldError = document.getElementById(fieldErrorId) as HTMLInputElement;
            fieldError.classList.add('d-block');
            const fieldErrorMsg = document.querySelector(`#${fieldErrorId} .invalid-feedback-msg`) as HTMLInputElement;
            fieldErrorMsg.textContent = errMsg;
        }
        function setValidator(codes: { key: string; msg: string }[]) {
            const messageError = codes.find(x => x.key == 'Message');
            if (messageError) {
                const messageField = document.getElementById('messageField') as HTMLInputElement;
                messageField.setCustomValidity(messageError.msg);
                const messageFieldMsg = document.querySelector('#messageField .messageFieldError') as HTMLInputElement;
                if (messageFieldMsg) {
                    messageFieldMsg.textContent = messageError.msg;
                }
            }

            const scheduleTimeError = codes.find(x => x.key == '$.scheduleTime');
            if (scheduleTimeError) {
                setNotValid(scheduleTimeError.msg, 'scheduleTimeField', 'scheduleTimeFieldError');
            }

            const scheduleDateError = codes.find(x => x.key == '$.scheduleDate');
            if (scheduleDateError) {
                setNotValid(scheduleDateError.msg, 'scheduleDateField', 'scheduleDateFieldError');
            }
            const alertErrors = codes.filter(x => x.key == 'Schedule Date' || x.key == 'Schedule Message');
            if (alertErrors) {
                const html = codes
                    .map(x => {
                        return `<div>${x.key}: ${x.msg}</div>`;
                    })
                    .join(',');
                swal.fire({
                    icon: 'error',
                    html: html
                });
            } else {
                swal.fire({
                    icon: 'error',
                    text: 'Invalid Form'
                });
            }
        }
        function clearNotValid(fieldId: string, fieldErrorId: string) {
            const field = document.getElementById(fieldId) as HTMLInputElement;
            field.setCustomValidity('');
            field.classList.remove('error-input-style');
            const fieldError = document.getElementById(fieldErrorId) as HTMLInputElement;
            fieldError.classList.remove('d-block');
            const fieldErrorMsg = document.querySelector(`#${fieldErrorId} .invalid-feedback-msg`) as HTMLInputElement;
            if (fieldErrorMsg) {
                fieldErrorMsg.textContent = t('valid.isRequired');
            }
        }
        function clearValidator() {
            // required
            // messageField validation
            const messageField = document.getElementById('messageField') as HTMLInputElement;
            messageField.setCustomValidity('');
            const messageFieldMsg = document.querySelector('#messageField .messageFieldError') as HTMLInputElement;
            if (messageFieldMsg) {
                messageFieldMsg.textContent = t('valid.isRequired');
            }

            // required
            // scheduleDate validation
            clearNotValid('scheduleDateField', 'scheduleDateFieldError');

            // required
            // scheduleTime validation
            clearNotValid('scheduleTimeField', 'scheduleTimeFieldError');
        }
        const submitForm = async (event: any) => {
            clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
                return;
            }
            let hasError = false;
            const scheduleTime = moment(timingChatMessage.value.scheduleTime, 'HH:mm', true);
            if (!scheduleTime.isValid()) {
                setNotValid(t('valid.isRequired'), 'scheduleTimeField', 'scheduleTimeFieldError');
                hasError = true;
            }
            const scheduleDate1 = moment(scheduleDate.value, 'DD/MM/YYYY');
            if (!scheduleDate1.isValid()) {
                setNotValid(t('valid.isRequired'), 'scheduleDateField', 'scheduleDateFieldError');
                hasError = true;
            }
            const scheduleDateString = scheduleDate1.format(DateConstants.SERVER_NODATIME_LOCAL_DATE_FORMAT);
            if (hasError) {
                return;
            }
            swal.showLoading();
            const apiReqest: SaveTimingMessageRequest = {
                id: timingChatMessage.value.id,
                message: timingChatMessage.value.message,
                scheduleDate: scheduleDateString,
                scheduleTime: scheduleTime.format('HH:mm:ss'),
                crmCustomerId: props.crmCustomerId,
                crmCustomerLeadId: props.crmCustomerLeadId
            };
            const apiResponse = await api.saveTimingMessage(apiReqest);
            if (apiResponse.validationErrors?.length) {
                setValidator(apiResponse.validationErrors);
                return;
            }
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage
                });
                return;
            }
            emit('saveTimingChatMessage', apiResponse.data);
            close();
            swal.close();
        };

        async function deleteTimingChatMessage() {
            emit('deleteTimingChatMessage', timingChatMessage.value.id);
        }

        return {
            isOpen,
            open,
            close,
            submitForm,
            isNew,
            deleteTimingChatMessage,
            dateConstants,
            scheduleDate,
            timingChatMessage,
            updateScheduleTime,
            updateScheduleDate,
            updateScheduleDateTime,
            loading,
            customerFullName
        };
    }
});
