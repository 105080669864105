
import { defineComponent, ref, PropType } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';

export default defineComponent({
    props: {
        onSaveClick: {
            type: Function as PropType<(remarks: string) => Promise<boolean>>,
            required: true
        }
    },
    components: {
        OverlayModal
    },
    name: 'CrmCustomerRemarksPopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const remarks = ref('');
        async function open(remarksParam: string) {
            remarks.value = remarksParam;
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        async function onSave() {
            const result = await props.onSaveClick(remarks.value);
            if (!result) {
                return;
            }
            emit('updateRemarks', remarks.value);
            close();
        }
        return {
            isOpen,
            open,
            close,
            remarks,
            onSave
        };
    }
});
