
import { defineComponent, toRefs, PropType, ref } from 'vue';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import { IdName } from '@/models/Interfaces';
import { CrmCustomerLeadEditorModel } from '@/models/CrmCustomers';

export default defineComponent({
    props: {
        modelValue: {
            type: Object as PropType<CrmCustomerLeadEditorModel>,
            required: true
        },
        leadTypes: {
            type: Array as PropType<IdName[]>,
            required: true
        },
        leadSources: {
            type: Array as PropType<IdName[]>,
            required: true
        },
        firstRowAllowSingle: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Multiselect
    },
    name: 'CrmCustomerLeadForm',
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const leadTypeField = ref();
        const leadTypeFieldError = ref();
        const leadSourceField = ref();
        const leadSourceFieldError = ref();
        const numberOfGuestsField = ref();
        const numberOfGuestsFieldError = ref();
        const { modelValue } = toRefs(props);
        const updateModel = (key: keyof CrmCustomerLeadEditorModel, value: any) => {
            modelValue.value[key] = value;
            emit('update:modelValue', modelValue.value);
        };
        function setValidator(codes: { key: string; msg: string }[]) {
            const leadTypeError = codes.find(x => x.key == 'LeadTypeId');
            if (leadTypeError) {
                leadTypeField.value?.setCustomValidity(leadTypeError.msg);
                if (leadTypeField.value) {
                    leadTypeFieldError.value.textContent = leadTypeError.msg;
                }
            }

            const leadSourceError = codes.find(x => x.key == 'LeadSourceId');
            if (leadSourceError) {
                leadSourceField.value?.setCustomValidity(leadSourceError.msg);
                if (leadSourceField.value) {
                    leadSourceFieldError.value.textContent = leadSourceError.msg;
                }
            }

            const numberOfGuestsError = codes.find(x => x.key == 'NumberOfGuests');
            if (numberOfGuestsError) {
                numberOfGuestsField.value?.setCustomValidity(numberOfGuestsError.msg);
                if (numberOfGuestsField.value) {
                    numberOfGuestsFieldError.value.textContent = numberOfGuestsError.msg;
                }
            }
        }
        function clearValidator() {
            // required
            // leadType validation
            leadTypeField.value?.setCustomValidity('');
            if (leadTypeFieldError.value) {
                leadTypeFieldError.value.textContent = t('valid.isRequired');
            }

            // required
            // leadSource validation
            leadSourceField.value?.setCustomValidity('');
            if (leadSourceFieldError.value) {
                leadSourceFieldError.value.textContent = t('valid.isRequired');
            }

            // required
            // numberOfGuests validation
            numberOfGuestsField.value?.setCustomValidity('');
            if (numberOfGuestsFieldError.value) {
                numberOfGuestsFieldError.value.textContent = t('valid.isRequired');
            }
        }
        return {
            crmCustomerLead: modelValue,
            updateModel,
            clearValidator,
            setValidator,
            leadTypeField,
            leadTypeFieldError,
            leadSourceField,
            leadSourceFieldError,
            numberOfGuestsField,
            numberOfGuestsFieldError
        };
    }
});
