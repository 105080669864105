
import { defineComponent, onMounted, ref, computed } from 'vue';
import CrmCustomerPageHeader from '@/components/CrmCustomers/CrmCustomerPageHeader.vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import {
    CrmCustomerLeadDetailsModel,
    CrmCustomerEditorModel,
    CrmCustomerMessage,
    CrmCustomerTimingChatMessage,
    CrmCustomerReminderDetailsModel,
    CrmCustomerLeadEditorModel,
    CrmCustomerLeadToUpdateDetalis
} from '@/models/CrmCustomers';
import { LeadStatus, LeadStatusColors, CrmCustomerLeadSaveChanges, CrmCustomerMessageType, QuickReplyMode, PaginationDirection } from '@/models/Enums';
import {} from '@/models/Enums';
import {
    SaveCrmCustomerLeadRequest,
    SendCrmCustomerMessageApiRequest,
    SaveCrmCustomerReminderResponse,
    SaveTimingMessageResponse,
    SaveCrmCustomerLeadResponse
} from '@/models/Interfaces';
import CrmCustomerEditorModal from '@/components/CrmCustomers/CrmCustomerEditorModal.vue';
import CrmCustomerChatMessagePopup from '@/components/CrmCustomers/CrmCustomerChatMessagePopup.vue';
import QuickReplyPopup from '@/components/CrmCustomers/QuickReplyPopup.vue';
import CrmCustomerReminderEditorModal from '@/components/CrmCustomers/CrmCustomerReminderEditorModal.vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import q from 'q';
import CrmCustomerRemarksPopup from '@/components/CrmCustomers/CrmCustomerRemarksPopup.vue';
import CrmCustomerLeadEditorModal from '@/components/CrmCustomerLead/CrmCustomerLeadEditorModal.vue';
import CrmCustomerLeadActionsPopup from '@/components/CrmCustomerLead/CrmCustomerLeadActionsPopup.vue';
import TimingChatMessagePopup from '@/components/CrmCustomers/TimingChatMessagePopup.vue';
import { onBeforeRouteLeave } from 'vue-router';

export default defineComponent({
    name: 'CrmCustomerLeadDetails',
    props: {
        crmCustomerLeadId: {
            type: String,
            required: true
        },
        crmCustomerId: {
            type: String,
            required: true
        }
    },
    components: {
        CrmCustomerPageHeader,
        CrmCustomerEditorModal,
        CrmCustomerChatMessagePopup,
        QuickReplyPopup,
        CrmCustomerRemarksPopup,
        CrmCustomerReminderEditorModal,
        CrmCustomerLeadEditorModal,
        CrmCustomerLeadActionsPopup,
        TimingChatMessagePopup
    },
    async setup(props) {
        let chatTimerId = 0;
        onBeforeRouteLeave(async () => {
            clearTimeout(chatTimerId);
            return true;
        });
        const loaded = ref(false);
        const { t } = useI18n();
        const messagedMarked = ref(false);
        const filterType = ref<string>('ALL');
        const reminderFilter = ref(1);
        const messageAttachments = ref<File[]>([]);
        const shortDescription = ref(true);
        const message = ref('');
        const msgSent = ref(false);
        const modalcrmCustomerReminderEditor = ref<InstanceType<typeof CrmCustomerReminderEditorModal>>();
        const modalQuickReply = ref<InstanceType<typeof QuickReplyPopup>>();
        const crmCustomerLead = ref(new CrmCustomerLeadDetailsModel());
        const modalCrmCustomerEditor = ref<InstanceType<typeof CrmCustomerEditorModal>>();
        const modalCrmCustomerChatMessage = ref<InstanceType<typeof CrmCustomerChatMessagePopup>>();
        const modalCrmCustomerLeadRemarks = ref<InstanceType<typeof CrmCustomerRemarksPopup>>();
        const modalCrmCustomerLeadEditor = ref<InstanceType<typeof CrmCustomerLeadEditorModal>>();
        const modalCrmCustomerLeadActions = ref<InstanceType<typeof CrmCustomerLeadActionsPopup>>();
        const modalTimingChatMessage = ref<InstanceType<typeof TimingChatMessagePopup>>();

        const remindersToShow = computed(() => {
            if (reminderFilter.value == 2) {
                return crmCustomerLead.value.reminders.filter(x => x.active);
            }
            return crmCustomerLead.value.reminders;
        });
        function openEditCrmCustomerModal() {
            const toEdit: CrmCustomerEditorModel = {
                id: crmCustomerLead.value.crmCustomerId,
                firstName: crmCustomerLead.value.crmCustomerFirstName,
                lastName: crmCustomerLead.value.crmCustomerLastName,
                email: crmCustomerLead.value.crmCustomerEmail,
                mobile: crmCustomerLead.value.crmCustomerMobile,
                birthdate: crmCustomerLead.value.crmCustomerBirthdate,
                businessClientId: crmCustomerLead.value.crmCustomerBusinessClientId,
                businessClientName: crmCustomerLead.value.crmCustomerBusinessClientName
            };
            modalCrmCustomerEditor.value?.open(toEdit);
        }
        function updateCrmCustomerDetails(crmCustomerReq: CrmCustomerEditorModel) {
            crmCustomerLead.value.crmCustomerFirstName = crmCustomerReq.firstName;
            crmCustomerLead.value.crmCustomerLastName = crmCustomerReq.lastName;
            crmCustomerLead.value.crmCustomerFullName = crmCustomerReq.lastName + ' ' + crmCustomerReq.firstName;
            crmCustomerLead.value.crmCustomerEmail = crmCustomerReq.email;
            crmCustomerLead.value.crmCustomerMobile = crmCustomerReq.mobile;
            crmCustomerLead.value.crmCustomerBirthdate = crmCustomerReq.birthdate;
            crmCustomerLead.value.crmCustomerBusinessClientId = crmCustomerReq.businessClientId;
            crmCustomerLead.value.crmCustomerBusinessClientName = crmCustomerReq.businessClientName;
        }
        async function setLeadNotActive(evt: Event) {
            evt.preventDefault();
            swal.showLoading();
            const req = new SaveCrmCustomerLeadRequest();
            req.id = crmCustomerLead.value.id;
            req.active = !crmCustomerLead.value.active;
            req.changes = CrmCustomerLeadSaveChanges.Active;
            const response = await api.saveCrmCustomerLead(req);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            crmCustomerLead.value.active = !crmCustomerLead.value.active;
            swal.fire({
                position: 'center',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        }
        let getNextMessages: (direction?: PaginationDirection) => void = (direction = PaginationDirection.Forward) => {
            console.log('function template for stupid composition api');
        };
        async function getMessages(direction = PaginationDirection.Forward, fromId?: string, showLoading = false) {
            if (showLoading) swal.showLoading();
            const chatResult = await api.GetCrmCustomerLeadMessages(
                props.crmCustomerId,
                props.crmCustomerLeadId,
                filterType.value,
                fromId,
                direction
            );
            if (chatResult.data) {
                messagedMarked.value = false;
                crmCustomerLead.value.timingChatMessages = chatResult.data.timingChatMessages;
                if (!fromId) {
                    crmCustomerLead.value.messages = chatResult.data.messages;
                } else if (direction == PaginationDirection.Backward) {
                    crmCustomerLead.value.messages.push(...chatResult.data.messages);
                } else {
                    crmCustomerLead.value.messages.unshift(...chatResult.data.messages);
                }
            }
            if (showLoading) swal.close();

            if (direction == PaginationDirection.Forward) {
                clearTimeout(chatTimerId);
                chatTimerId = setTimeout(getNextMessages, 30 * 1000);
            }
        }
        getNextMessages = async (direction = PaginationDirection.Forward) => {
            let fromId: string | undefined = undefined;

            if (crmCustomerLead.value.messages.length > 0) {
                fromId = direction == PaginationDirection.Backward
                    ? crmCustomerLead.value.messages[crmCustomerLead.value.messages.length - 1].id
                    : crmCustomerLead.value.messages[0].id;
            }
            await getMessages(direction, fromId);
        };
        function openCrmCustomerChatMessageModal() {
            modalCrmCustomerChatMessage.value?.open(
                crmCustomerLead.value.crmCustomerId,
                crmCustomerLead.value.crmCustomerFullName,
                crmCustomerLead.value.id
            );
        }
        function openAddQuote() {
            // TODO
        }

        function changeChatType(type: string) {
            filterType.value = type;
            getMessages(PaginationDirection.Forward, undefined, true);
        }

        function openTimingChatMessagePopup(timingChatMessage: CrmCustomerTimingChatMessage | null = null) {
            modalTimingChatMessage.value?.open(crmCustomerLead.value.crmCustomerFullName, timingChatMessage);
        }
        async function deleteTimingChatMessage(id: string) {
            if (!id) {
                return false;
            }
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('delete.alert'),
                customClass: {
                    confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('businessClient.deleteContact.delete'),
                cancelButtonText: t('businessClient.deleteContact.undelete')
            });
            if (!swalResult.isConfirmed) {
                return false;
            }
            await q.delay(200);
            swal.showLoading();
            const response = await api.deleteTimingChatMessage(id);
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return false;
            }
            const indexToDelete = crmCustomerLead.value.timingChatMessages.findIndex(x => x.id == id);
            crmCustomerLead.value.timingChatMessages.splice(indexToDelete, 1);
            swal.close();
            return true;
        }
        function getChatMessageClass(msg: CrmCustomerMessage) {
            let msgClass = '';
            if (msg.type == CrmCustomerMessageType.SYSTEM) {
                msgClass = 'crm-customer-lead-chat-message-from-system';
            } else if (msg.type == CrmCustomerMessageType.CUSTOMER) {
                msgClass = 'crm-customer-lead-chat-message-from-customer';
            } else if (msg.type == CrmCustomerMessageType.LEAD) {
                // if (msg.isMe) {
                msgClass = 'crm-customer-lead-chat-message-from-me';
                // } else {
                //     msgClass = 'crm-customer-lead-chat-message-from-user';
                // }
            }
            return msgClass;
        }
        function callUploadingFunction(): void {
            document.getElementById('msg-attachment-input')?.click();
        }
        function formatChatDate(date: string) {
            const d = moment(date, 'DD/MM/YYYY HH:mm');
            return moment().isSame(d, 'day') ? d.format('HH:mm') : d.format('DD/MM/YYYY HH:mm');
        }
        async function downloadAttachment(attachmentId: string, attachmentName: string) {
            console.log(attachmentId);
            swal.showLoading();
            const apiResponse = await api.downloadCrmCustomerMessageAttachment(attachmentId);
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return;
            }
            const blob = apiResponse.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = attachmentName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            swal.close();
        }
        const uploadingAttachmentValidation = (event: Event) => {
            const target = event.target as HTMLInputElement;
            const files = target.files;
            const maxSize = 5242880; // 5 MB
            if (!files) return;
            swal.showLoading();
            const validFiles: File[] = [];
            const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf'];
            for (const file of files) {
                const fileExt = file.name.split('.').pop();

                if (!fileTypes.includes(fileExt!)) {
                    swal.fire({
                        icon: 'error',
                        text: `Error: Unsupported file type for ${file.name}.`
                    });
                    return;
                }

                if (file.size > maxSize) {
                    swal.fire({
                        icon: 'error',
                        text: `File size for ${file.name} should be less than or equal to 5 MB.`
                    });
                    return;
                }

                validFiles.push(file);
            }

            if (target.files) {
                messageAttachments.value.push(...Array.from(validFiles));
            }
            swal.close();
        };

        function openSelectQuickReply() {
            modalQuickReply.value?.open(false, true, QuickReplyMode.Select);
        }

        const adjustTextareaHeight = () => {
            const textarea = document.getElementById('textMessage');
            if (textarea) {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        };
        async function sendMsg(quickReplyId: string | null = null) {
            msgSent.value = true;
            if (!message.value.trim() && !messageAttachments.value.length && !quickReplyId) {
                msgSent.value = false;
                return;
            }
            if (msgSent.value) {
                swal.showLoading();
                const apiRequest: SendCrmCustomerMessageApiRequest = {
                    crmCustomerId: props.crmCustomerId,
                    message: message.value,
                    quickReplyId: quickReplyId,
                    crmCustomerLeadId: props.crmCustomerLeadId,
                    attachments: messageAttachments.value
                };
                const apiResult = await api.SendCrmCustomerMessage(apiRequest);
                if (apiResult.validationErrors?.length) {
                    const html = apiResult.validationErrors
                        .map(x => {
                            return `<div>${x.key}: ${x.msg}</div>`;
                        })
                        .join(',');
                    swal.fire({
                        icon: 'error',
                        html: html
                    });
                    return;
                }
                if (apiResult.errorMessage || !apiResult.data) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage || 'No data'
                    });
                    return;
                }
                message.value = '';
                messageAttachments.value = [];
                await getNextMessages();
                msgSent.value = false;
                if (quickReplyId) {
                    modalQuickReply.value?.close();
                } else {
                    const textarea = document.getElementById('textMessage');
                    if (textarea) {
                        textarea.style.height = 'initial';
                    }
                }

                swal.close();
            }
        }
        async function deleteMsgAttachment(attachmentIndex: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: 'Are you sure you want to delete this attachment?',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonText: 'Delete',
                denyButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) return;
            messageAttachments.value.splice(attachmentIndex, 1);
        }
        function openCrmCustomerRemarksModal() {
            modalCrmCustomerLeadRemarks.value?.open(crmCustomerLead.value.remarks);
        }
        async function onSaveCrmCustomerLeadRemarks(remarks: string) {
            swal.showLoading();
            const req = new SaveCrmCustomerLeadRequest();
            req.id = props.crmCustomerLeadId;
            req.remarks = remarks;
            req.changes = CrmCustomerLeadSaveChanges.Remarks;
            const response = await api.saveCrmCustomerLead(req);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return false;
            }
            swal.close();
            return true;
        }
        function updateRemarks(newRemarks: string) {
            crmCustomerLead.value.remarks = newRemarks;
        }
        function openRemiderEditorPopup(reminder: CrmCustomerReminderDetailsModel | null = null) {
            modalcrmCustomerReminderEditor.value?.open(reminder);
        }
        function saveReminder(reminder: SaveCrmCustomerReminderResponse) {
            const existReminder = crmCustomerLead.value.reminders.find(x => x.id == reminder.id);
            if (existReminder) {
                existReminder.id = reminder.id;
                existReminder.name = reminder.name;
                existReminder.active = reminder.active;
                existReminder.alertDateTime = reminder.alertDateTime;
                existReminder.onlyForMe = reminder.onlyForMe;
                existReminder.relativeInterval = reminder.relativeInterval;
            } else {
                const toSave: CrmCustomerReminderDetailsModel = {
                    id: reminder.id,
                    name: reminder.name,
                    active: reminder.active,
                    alertDateTime: reminder.alertDateTime,
                    onlyForMe: reminder.onlyForMe,
                    relativeInterval: reminder.relativeInterval
                };
                crmCustomerLead.value.reminders.push(toSave);
            }
            modalcrmCustomerReminderEditor.value?.close();
        }
        function deleteReminder(id: string) {
            const existReminderIndex = crmCustomerLead.value.reminders.findIndex(x => x.id == id);
            crmCustomerLead.value.reminders.splice(existReminderIndex, 1);
            modalcrmCustomerReminderEditor.value?.close();
        }
        function editLead() {
            const toEditModel: CrmCustomerLeadEditorModel = {
                active: crmCustomerLead.value.active,
                crmCustomerId: crmCustomerLead.value.crmCustomerId,
                description: crmCustomerLead.value.description,
                id: crmCustomerLead.value.id,
                leadSourceId: crmCustomerLead.value.leadSourceId,
                leadTypeId: crmCustomerLead.value.leadTypeId,
                numberOfGuests: crmCustomerLead.value.numberOfGuests,
                status: crmCustomerLead.value.status,
                remarks: crmCustomerLead.value.remarks
            };
            modalCrmCustomerLeadEditor.value?.open(toEditModel);
        }
        function openCrmCustomerLeadActionsModal() {
            modalCrmCustomerLeadActions.value?.open();
        }
        async function onSelectAction(status: LeadStatus) {
            swal.showLoading();
            const req = new SaveCrmCustomerLeadRequest();
            req.status = status;
            req.id = props.crmCustomerLeadId;
            req.changes = CrmCustomerLeadSaveChanges.Status;
            const apiResponse = await api.saveCrmCustomerLead(req);
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return null;
            }
            crmCustomerLead.value.status = status;
            swal.close();
        }
        async function markMessagesAsRead() {
            if (crmCustomerLead.value.messages && crmCustomerLead.value.messages.length > 0 && messagedMarked.value == false) {
                const lastMessage = crmCustomerLead.value.messages[0];
                const response = await api.markCrmCustomerLeadMessageAsRead(props.crmCustomerId, props.crmCustomerLeadId, lastMessage.id);
                if (response.error || response.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                    return;
                }
                messagedMarked.value = true;
            }
        }
        function saveTimingChatMessage(data: SaveTimingMessageResponse) {
            const existTimingChatMessage = crmCustomerLead.value.timingChatMessages.find(x => x.id == data.id);
            if (existTimingChatMessage) {
                existTimingChatMessage.message = data.message;
                existTimingChatMessage.scheduleDateTime = data.scheduleDateTime;
            } else {
                const newTimingChatMessage: CrmCustomerTimingChatMessage = {
                    id: data.id,
                    message: data.message,
                    scheduleDateTime: data.scheduleDateTime,
                    relativeInterval: data.relativeInterval
                };
                crmCustomerLead.value.timingChatMessages.unshift(newTimingChatMessage);
            }
        }
        async function deleteTimingChatMessage2(id: string) {
            const isOk = await deleteTimingChatMessage(id);
            if (isOk) {
                modalTimingChatMessage.value?.close();
            }
        }
        function updatecrmCustomerLeadDetails(response: CrmCustomerLeadToUpdateDetalis) {
            crmCustomerLead.value.leadTypeName = response.leadTypeName;
            crmCustomerLead.value.leadTypeId = response.leadTypeId;
            crmCustomerLead.value.leadSourceId = response.leadSourceId;
            crmCustomerLead.value.leadSourceName = response.leadSourceName;
            crmCustomerLead.value.numberOfGuests = response.numberOfGuests;
            crmCustomerLead.value.description = response.description;
        }
        const onMountedCall = async () => {
            swal.showLoading();
            const apiPromise = api.getCrmCustomerLead(props.crmCustomerLeadId);
            const apiResponse = await apiPromise;
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return;
            }
            crmCustomerLead.value = apiResponse.data.crmCustomerLead;
            await getMessages();
            loaded.value = true;
            const readMsgCallback = (entries: any, observer: any) => {
                entries.forEach((entry: any) => {
                    markMessagesAsRead();
                });
            };

            const chatObserver = new IntersectionObserver(readMsgCallback, {
                threshold: [0.5] // If 50% of the element is in the screen, we count it!
                // Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
            });
            const chatContainer = document.getElementById('crm-customer-lead-chat-div');

            if (chatContainer) {
                chatObserver.observe(chatContainer);
            }
            loaded.value = true;
            swal.close();
        };

        onMounted(onMountedCall);
        return {
            crmCustomerLead,
            LeadStatus,
            LeadStatusColors,
            loaded,
            shortDescription,
            setLeadNotActive,
            openEditCrmCustomerModal,
            modalCrmCustomerEditor,
            updateCrmCustomerDetails,
            openCrmCustomerChatMessageModal,
            getNextMessages,
            modalCrmCustomerChatMessage,
            openAddQuote,
            changeChatType,
            filterType,
            openTimingChatMessagePopup,
            deleteTimingChatMessage,
            CrmCustomerMessageType,
            getChatMessageClass,
            formatChatDate,
            downloadAttachment,
            callUploadingFunction,
            uploadingAttachmentValidation,
            openSelectQuickReply,
            message,
            adjustTextareaHeight,
            sendMsg,
            messageAttachments,
            deleteMsgAttachment,
            modalQuickReply,
            openCrmCustomerRemarksModal,
            modalCrmCustomerLeadRemarks,
            onSaveCrmCustomerLeadRemarks,
            updateRemarks,
            reminderFilter,
            openRemiderEditorPopup,
            modalcrmCustomerReminderEditor,
            saveReminder,
            deleteReminder,
            modalCrmCustomerLeadEditor,
            editLead,
            openCrmCustomerLeadActionsModal,
            modalCrmCustomerLeadActions,
            onSelectAction,
            modalTimingChatMessage,
            saveTimingChatMessage,
            deleteTimingChatMessage2,
            remindersToShow,
            updatecrmCustomerLeadDetails
        };
    }
});
